import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch, NavLink, Link } from "react-router-dom";
import { data } from "../data/eng_data";
import Logo from "../images/logo_1.png";
import { IoIosHome } from "react-icons/io";
// import Home from "./Home";
import Home2023 from "./Home2023";
import About from "./About";
import Programme from "./Programme";
import Media from "./Media";
import Press from "./Press";
import Events from "./Events";
import Donate from "./Donate";
import Contact from "./Contact";
import DynamicScrollToTop from "../utils/scrollToTop";

// Press
import Press2021 from "./press/Press2021";
import Press2020 from "./press/Press2020";

const Navigation = () => {
  const links = data.menuLinks;

  const [isActive, setisActive] = useState(false);

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);

  return (
    <BrowserRouter>
      <DynamicScrollToTop />

      <nav
        className={
          scroll
            ? "navbar navbar-expand-lg navbar-light bg-light sticky-top --not-top"
            : "navbar navbar-expand-lg navbar-light bg-light fixed-top"
        }
      >
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src={Logo} className="vaclaf-logo" alt="Vaclaf" />
            <span className="d-sm-none md-hidden pl-2">:10</span>
          </Link>
          

          <button
            onClick={() => {
              setisActive(!isActive);
            }}
            className={`navbar-toggler ${isActive ? "is-active" : ""}`}
            type="button"
            data-toggle="collapse"
            data-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
              <NavLink
                activeClassName="active"
                className="nav-link home-icon mr-3"
                to="/"
              >
                <IoIosHome size={"1.5em"} color="#e92054" />
              </NavLink>
              {links.map((link) => (
                <li
                  className="nav-item"
                  key={link.id}
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  <NavLink
                    activeClassName="active"
                    className="nav-link"
                    to={link.link}
                  >
                    {link.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>

      <Switch>
        {/* <Route exact path="/" component={Home} /> */}
        <Route exact path="/" component={Home2023} />
        <Route exact path="/about" component={About} />
        <Route exact path="/programme" component={Programme} />
        <Route exact path="/media" component={Media} />
        <Route exact path="/press" component={Press} />
        <Route exact path="/events" component={Events} />
        <Route exact path="/donate" component={Donate} />
        <Route exact path="/contact" component={Contact} />
        {/* Press Releases */}
        <Route exact path="/press/2021">
          <Press2021 />
        </Route>
        <Route exact path="/press/2020" component={Press2020} />
      </Switch>
    </BrowserRouter>
  );
};

export default Navigation;
