import React, { Component } from "react";

export default class Press2021 extends Component {
  render() {
    return (
      <div className="container fadeIn duration-700 p-70">
        <h1>"Ethos"</h1>
        <span className="badge badge-secondary mt-1">Press Release</span>
        <h5>23.09. - 02.10.2021.</h5>
        <p className="font-weight-bold">
          Deveto izdanje Vareš Classic Festivala kroz temu "Ethos" predstavlja
          vrhunsku muzičku umjetnost i umjetnike iz BiH i inozemstva;
          angažiranu, životno važnu, dostupnu svima i s važnom misijom koja nas
          sve spaja - nastaviti kreirati i u najtežim okolnostima te zajedničkim
          snagama dati najbolje i najiskrenije, od sebe i od Muzike.
        </p>
        <p className="font-weight-bold">
          Polazeći od izvorišta vlastitog etosa, orijentiranog na vrhunsku
          Muziku u najširem smislu riječi, VaClaF 2021 će predstaviti
          fenomenalne izvođače različitih generacija i žanrovskih opredjeljenja:
          od mladih nada do iskusnih profesora, od rane muzike baroka, preko
          vječnih klasika, tanga, perzijske muzike, sevdaha, do suvremenih djela
          živućih kompozitora i kompozitorica.
        </p>
        <p className="font-weight-light">
          Suočeni s iznimnim vremenima, primorani na prilagodbu, deveto izdanje
          VaClaF-a ponudit će ponovo sve koncerte i sadržaje potpuno besplatno,
          ali ovaj put, uz poštivanje svih epidemioloških mjera, nadamo se
          isključivo publici uživo u Varešu.
        </p>
        <p className="font-weight-light">
          Tako će sjajna austrijska pjevačica bh korijena Nataša Mirković, uz
          francuskog serpetnistu Michela Godarda u četvrtak 23.09. svečano
          otvoriti festival raznolikim programom rane i etnom inspirirane
          muzike, a oboje će već sljedeći dan, petak 24.09., nastupiti i uz
          Oberton String Octet, jedan od najperspektivnijih mladih austrijskih
          ansambala u programu “Tangabile”.
        </p>
        <p className="font-weight-light">
          Subota, 25.09. rezervirana je za sjajnog kanadskog muzičara perzijskih
          korijena Amira Amirija, koji će na santuru predstaviti jedinstven
          autorski program, a u nedjelju 26.09. će također udružiti snage s
          Oberton oktetom u centralnom programu festivala, Miholjskom koncertu
          pod ovogodišnjom temom “Ethos”. Kao solistice pridružit će se i Ivana
          Nikolić na oboi te Andrea Nikolić na violini i violi, a poseban gost
          na kontrabasu bit će Fahrudin Strojil iz orkestra Sarajevske
          Filharmonije.
        </p>
        <p className="font-weight-light">
          U već tradicionalnom programu “VaClaF Rising Stars” predstavit će se
          mlada bh violinistica Alma Dizdar, uz Mirona Konjevića na čembalu i
          Belmu Alić na violončelu, u četvrtak 30.09.
        </p>
        <p className="font-weight-light">
          Svečano zatvaranje ovogodišnjeg VaClaF-a u subotu 02.10. obilježit će
          sjajni međunarodno nagrađivani bh umjetnik, “Singer of Tales'', Damir
          Imamović u posebnom programu s prijateljima za Vareš Classic Festival.
        </p>
        <p className="font-weight-light">
          Svi koncerti od 23. do 30.09. održat će se u 19 sati u jedinstvenim
          ambijentima Stare (23., 25., 30.09.) i Župne crkve sv. Mihovila
          (24.&26.09.), a mladi pijanisti nastupit će u 18h u dvorani Radničkog
          doma. Svečano zatvaranje u subotu 02.10. predviđeno je u 19h u velikoj
          dvorani Centra za kulturu i edukaciju - Radnički dom Vareš.
        </p>
        <p className="font-weight-light">
          Naposljetku, molimo Vas za razumijevanje i podršku u ovim neizvjesnim
          vremenima, pogotovo ukoliko dođe do nepredviđenih izmjena u posljednji
          trenutak. Radujemo se svakoj sekundi podijeljene Umjetnosti s Vama!
        </p>
        <p className="font-weight-light">
          Ovogodišnji VaClaF, u organizaciji UPG Emil Milo Cipra Vareš i
          partnerstvu s Kammermusikverein WISE, Župom Vareš, Centrom za kulturu
          i edukaciju Vareš i suradnji sa sjajnim Austrijskim kulturnim forumom
          Sarajevo, značajno su podržali i Fondacija za muzičke, scenske i
          likovne umjetnosti Sarajevo, Institut Francaise i Francuska ambasada
          Sarajevo te prijateljske kompanije i organizacije iz Vareša kao i
          pojedinci u Varešu i dijaspori.
        </p>
        <p className="font-weight-light">
          Vašu podršku festivalu u “pay as you wish” formatu možete donirati
          tokom čitavog festivala putem{" "}
          <a href="/donate">www.vaclaf.com/donate</a> te dobiti pristup i našim
          koncertima online.
        </p>
        <br />
        Dobrodošli, od srca, u Vaš i naš Vareš! Radujemo se još jednom
        jedinstvenom festivalskom muzičkom doživljaju!
        <br />
        <strong>Vaš VaClaF</strong>
      </div>
    );
  }
}
