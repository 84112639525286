import React, { Component } from "react";
import SponsorImage from "../images/sponsor_0.jpg";
import SponsorImage2 from "../images/sponsor_1.png";
import OkfSarajewo from "../images/sarajewo_ofk.png";
import PaypalDonate from "../images/paypal_donate.png";
import { data } from "../data/eng_data";

export default class Donate extends Component {
  render() {
    const donate = data.donate_info;
    return (
      <div className="container fadeIn duration-700 p-70">
        <h1>Support Us</h1>
        <p className="text-left">
          Started enthusiastically in 2009 by Petar Jelić and Andrea Nikolić the
          annual "children of Vareš to the Vareš audience" Miholjski concert
          quickly developed, winning everyone's hearts, supported by many
          friends and people of Vareš.n 2013 it transformed into the
          international chamber music festival on Andrea's initiative, supported
          by Mr. Jelić, newly founded Emil Milo Cipra Society, its friends and
          supporters, the Nikolić family and the parish priest of Vareš at that
          time - fra Mirko Majdandžić.
          <br />
          <br />
          Since then VaClaF has continuously been developing and proudly
          collaborating with: Chamber Music Association WISE Vienna, Art Colony
          Borovica, Vareš Public Library, Center for Culture Vareš, Music
          Academy and Music Highschool Sarajevo, Elementary and Music School
          Vareš, Austrian Cultural Forum and many other partners. Some of our
          main partners and supporters throughout the years: Vareš Parish, Vareš
          Municipality, Federal Ministry of Culture and Sport, Foundation for
          Music, Visual and Performing Arts Sarajevo, Austrian Embassy Sarajevo
          & Austrian Ministry of Foreign Affairs (BMEIA), Hrvatski radio
          Bobovac, List Bobovac, Fondacija za razvoj i obnovu regije Vareš,
          BHRT, Oslobođenje Special thank you goes to all of our many
          supporters, Friends of Miholjski koncert and VaClaF*, consisted of
          many Vareš firms and individuals, as well as friends of Vareš, who
          made it possible for us to develop and excel!
        </p>

        <div className="mx-auto mb-3">
          <img src={OkfSarajewo} className="img-fluid" alt="OKF Sarajewo" />
          <img src={SponsorImage2} className="img-fluid" alt="Sponsors" />
          <img src={SponsorImage} className="img-fluid" alt="Sponsors" />
        </div>
        <h5 className="font-weight-bolder">
          Support us by becoming our patron, sponsor or partner, or donate
          directly:
        </h5>
        <hr />
        <div className="container mx-auto">
          <div className="row">
            <div className="col-md-3">
              <form
                action="https://www.paypal.com/cgi-bin/webscr"
                method="post"
                target="_top"
              >
                <input type="hidden" name="cmd" value="_donations" />
                <input
                  type="hidden"
                  name="business"
                  value="wisemusicwien@gmail.com"
                />
                <input
                  type="hidden"
                  name="item_name"
                  value="Support for Vaclaf Music Festival"
                />
                <input type="hidden" name="currency_code" value="EUR" />
                <input
                  type="image"
                  src={PaypalDonate}
                  className="img-fluid"
                  border="0"
                  name="submit"
                  title="Support Vaclaf with Paypal"
                  alt="Support Vaclaf via Paypal"
                />
              </form>
            </div>
            <div className="col-md-9">
              {" "}
              <ul className="list-group">
                {donate.map((item) => (
                  <li key={item.id} className="list-group-item">
                    {" "}
                    {item.text}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
