import React, { Component } from "react";
import { data } from "../data/eng_data";
import Carousel from "./Carousel";

export default class About extends Component {
  render() {
    const artists = data.artists;
    return (
      <div className="container fadeIn duration-700 p-70">
        <h1>About Us</h1>
        <p className="font-weight-bold">
          VaClaF: Vareš Classic Festival saw its premiere in September 2013, on
          the fifth anniversary of the traditional "Miholjski" concert of
          classical music in Vareš. Its goal is to bring high quality music and
          art to the widest possible audience through a collaboration of top
          class artists from Bosnia and Herzegovina and all around the world.
        </p>
        <p className="font-weight-light">
          In the unique environment of Vareš’s beautiful locations such as the
          historic Old Church - the oldest in BH, the new St. Mihovil Church,
          the Mill, Workers Home, as well as churches, outdoor and unusual
          remote locations in Borovica, Vijaka and old blacksmith workshops in
          Oćevija, VaClaF has developed into an interdisciplinary art movement
          with socially engaging concepts, offering Art free of charge and
          accessible to all. Produced and curated by Andrea Nikolić, the
          festival's general and artistic director, VaClaF has presented over 50
          original concepts and programmes so far and Vareš has hosted hundreds
          of artists of all generations from Bosnia and Herzegovina and abroad.
        </p>

        <Carousel />
        <hr />
        <p className="font-weight-light">
          Bringing together world class artists and educating new generations,
          the festival has held the status of international importance for the
          state of Bosnia and Herzegovina since 2015 and has emerged to
          international recognition as part of the Year of Culture Austria - BiH
          2016. It has been supported by the institutions of both of these
          countries ever since. VaClaF puts a strong focus on New Music,
          commissioning and premiering new works and concepts. Developing into
          an interdisciplinary festival, it has hosted visual art exhibitions,
          literature and poetry evenings, interdisciplinary recitals,
          masterclasses and seminars and is planning to expand even further.
        </p>
        <br />
        <p className="font-weight-light">
          VaClaF takes pride in its socially engaging activities, tackling
          gender equality and education issues, making art accessible and
          relevant to society today. Since 2017 an annual concert "Echoes from
          Vareš" has been held in Vienna, Austria. In 2018, national television
          BHRT made a documentary about the Festival named "Music I call Home".
          A CD with the new works premiered at and written for VaClaF, played by
          WISE: Wien International Soloists Ensemble & Andrea Nikolić will be
          published in 2020.{" "}
        </p>

        <br />
        <hr />
        <br />

        <h2>ARTISTS</h2>

        <p className="font-weight-bold">
          Some of our artists throughout the years (more to be found in our
          booklets and programmes):
        </p>
        {artists.map((artist) => (
          <div key={artist.id} className="container">
            <div className="subtitle">
              <h5 className="uppercase">{artist.title}</h5>
            </div>
            <div className="col-11 ml-auto pb-3">
              <p className="font-weight-light">{artist.description}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
