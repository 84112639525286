import React from "react";
import { events, passed_events } from "../data/events_2023";

const PassedEvents = passed_events.map((event) => {
  return (
    <tr>
      <td className="lead">
        <a href={event.link}>{event.title}</a>
      </td>
      <td>{event.date}</td>
      <td className="semibold">{event.location}</td>
      <td>
        <ul className="p-0 events-list">
          {event.artists.map((artist) => (
            <li>{artist}</li>
          ))}
        </ul>
      </td>
      <td>
        <ul className="p-0 events-list">
          {event.repertoar.map((rep) => (
            <li>{rep}</li>
          ))}
        </ul>
      </td>
    </tr>
  );
});

const UpcomingEvents = events.map((event) => {
  return (
    <tr>
      <td className="lead">
        <a href={event.link}>{event.title}</a>
      </td>
      <td>{event.date}</td>
      <td className="semibold">{event.location}</td>
      <td>
        <ul className="p-0 events-list">
          {event.artists.map((artist) => (
            <li>{artist}</li>
          ))}
        </ul>
      </td>
      <td>
        <ul className="p-0 events-list">
          {event.repertoar.map((rep) => (
            <li>{rep}</li>
          ))}
        </ul>
      </td>
    </tr>
  );
});

const Events = () => {
  return (
    <div className="container p-70 fadeIn duration-700">
      <h1>Events 2023</h1>
      {/* <h4 className="pt-5">Introduction - August</h4>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Event</th>
            <th scope="col">Date</th>
            <th scope="col">Location</th>
            <th scope="col">Artists</th>
            <th scope="col">Repertoire</th>
          </tr>
        </thead>
        <tbody>{PassedEvents}</tbody>
      </table> */}
      <h4 className="pt-2">“Back to the Roots” - 
Festival | Academy | Residencies | On Tour</h4>
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Event</th>
            <th scope="col">Date</th>
            <th scope="col">Location</th>
            <th scope="col">Artists</th>
            <th scope="col">Repertoire</th>
          </tr>
        </thead>
        <tbody>{UpcomingEvents}</tbody>
      </table>
      {/* <hr />
      <h2>Specials:</h2>
      <p>
        STAZAMA PRIRODE: muzičko-poetsko-kulinarska šetnja Nedjelja, 25.9.
        12:30h polazak Ribnjak Sirena → Wigwam.
        <br />U suradnji s Udrugom žena Strica - Zaruđe, Andrea Nikolić & Wu
        Bing
      </p>
      <p>
        IZLOŽBA: 10 godina Vareš Classic Festivala, festivalski plakati uz
        koncerte u crkvama i Radničkom domu, Vareš 15.9.-1.10.2022.
      </p> */}
    </div>
  );
};

export default Events;
