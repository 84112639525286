import React, { Component } from "react";
import { data } from "../data/eng_data";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";

export default class Carousel extends Component {
  render() {
    const slides = data.slides;
    return (
      <Slider>
        {slides.map((slide) => (
          <div
            key={slide.id}
            className="slider-content"
            style={{
              background: `url('${slide.url}') no-repeat center center`,
            }}
          >
            <div className="inner">
              <p className="slide-title">{slide.title}</p>
              <p className="slide-subtitle">{slide.subtitle}</p>
            </div>
          </div>
        ))}
      </Slider>
    );
  }
}
