import React from "react";
import { Link } from "react-router-dom";

import { data } from "../data/eng_data";

const Press = () => {
  return (
    <div className="container p-70 fadeIn duration-700">
      <h1>Press Releases</h1>
      <ul className="list-group">
        {data.press_releases.map((release) => {
          return (
            <li
              className="list-group-item d-flex justify-content-between"
              key={release.id}
            >
              <Link to={`/press/${release.year}`}>
                {release.year} - {release.name}
              </Link>
              <div className="badges">
                {release.lang.map((i) => (
                  <span key={i.id} className="badge badge-secondary mr-1">
                    {i.name}
                  </span>
                ))}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Press;
