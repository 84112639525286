import React, { Component } from "react";
import Footer from './Footer';
export default class Contact extends Component {
  render() {
    return (
      <div className="container fadeIn duration-700 p-70 h-100">
        <div className="row">
          <div className="col mb-3">
            <h2>Contact</h2>
            <p className="font-weight-light">GIVE US A SHOUT</p>

            <form action="">
              <div className="form-group">
                <input
                  type="name"
                  className="form-control"
                  id="name"
                  placeholder="Your name here.."
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Your email here.."
                />
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  id="messagearea"
                  placeholder="Your message..."
                  rows="3"
                ></textarea>
              </div>
              <button type="submit" className="btn-p w-100">
                Send
              </button>
            </form>
          </div>
          <div className="col-md mb-3">
            <h2>Find Us Here</h2>
            <p className="font-weight-light">YOU CAN FIND US HERE</p>
            <div className="map">
              <iframe
                title="mapframe"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1701.7919795394255!2d18.327083697759754!3d44.16459090525375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDTCsDA5JzUxLjgiTiAxOMKwMTknNDEuOSJF!5e0!3m2!1sen!2sba!4v1502971901766"
                width="100%"
                height="400"
                frameBorder="0"
                samesite="none"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
        <div className="container">
          
        </div>
        <Footer/>
      </div>
    );
  }
}
