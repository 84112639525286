export const events = [
  {
    id: 1,
    title: "Svečano otvorenje | Festive Opening",
    date: "13.10.2023 - 19:00",
    location: "Radnički dom Vareš",
    artists: [
      "CIPRA QUARTET",
      "Andrea Nikolić",
      "Ivana Nikolić - oboa/oboe",
      "Pablo Carpio Hernández - flauta",
      "Vedrana Šimić - sopran/soprano",
      "Andrea Nikolić - umjetničko vodstvo/artistic leadership",      
    ],
    repertoar: ["Cipra"," Bürger", "Bacewicz", "Bešlić - Gàl", "Winterberg"],
    link: ""
  },  
  {
    id: 2,
    title: "VaClaF On Tour! ",
    date: "13.10.2023 - 19:00",
    location: "Bosansko narodno pozorište",
    artists: [
      "CIPRA QUARTET",
      "Andrea Nikolić",
      "Angela Chia Chen Lin - violine/violins",
      "Ivana Nikolić - oboa/oboe",
      "Weronika Izert - viola",
      "Weronika Strugala - violoncello",
      "Vedrana Šimić - sopran/soprano",
      "Andrea Nikolić - umjetničko vodstvo/artistic leadership"
    ],
    repertoar: ["Cipra"," Bürger", "Bacewicz", "Bešlić - Gàl", "Winterberg"],
    link: ""
  },
  {
    id: 3,
    title: "VaClaF On Tour! ",
    date: "15.10.2023 - 19:00",
    location: "UMJETNIČKA GALERIJA BiH, SARAJEVO",
    artists: [
      "CIPRA QUARTET",
      "Andrea Nikolić",
      "Angela Chia Chen Lin - violine/violins",
      "Ivana Nikolić - oboa/oboe",
      "Weronika Strugala - violoncello",
      "Belma Šarančić - harmonika/accordion",
      "Andrea Nikolić - umjetničko vodstvo/artistic leadership",
    ],
    repertoar: ["Bürger", "Bacewicz", "Bešlić - Gàl", "Piazzolla", "Čuperjani"],
    link: "",
  },
  {
    id: 4,
    title: "VaClaF On Tour! - MixArt Festival",
    date: "16.10.2023 - 19:00",
    location: "MUZIČKI CENTAR PAVAROTTI - Koncertna sala, MOSTAR",
    artists: [
      "CIPRA QUARTET",
      "Andrea Nikolić",
      "Angela Chia Chen Lin - violine/violins",
      "Ivana Nikolić - oboa/oboe",
      "Weronika Strugala - violoncello",
      "Belma Šarančić - harmonika/accordion",
      "Andrea Nikolić - umjetničko vodstvo/artistic leadership"
    ],
    repertoar: ["Bürger", "Bacewicz", "Bešlić - Gàl", "Piazzolla", "Čuperjani"],
    link: "",
  },

  {
    id: 5,
    title: "VaClaF On Tour!",
    date: "17.10.2023 - 18:00",
    location: " GALERIJA MEĐUNARODNIH PORTRETA  TUZLA",
    artists: [
      "CIPRA QUARTET",
      "Andrea Nikolić",
      "Angela Chia Chen Lin - violine/violins",
      "Ivana Nikolić - oboa/oboe",
      "Weronika Izert - viola",
      "Weronika Strugala - violoncello",
      "Belma Šarančić - harmonika/accordion",
      "Andrea Nikolić - umjetničko vodstvo/artistic leadership"
    ],
    repertoar: ["Bürger", "Bacewicz", "Bešlić - Gàl", "Piazzolla", "Čuperjani"],
    link: "",
  },
  {
    id: 6,
    title: "VaClaF",
    date: "18.10.2023 - 19:00",
    location: "RADNIČKI DOM VAREŠ",
    artists: ["Boško Jović - gitara/guitar", "Andrea Nikolić violina/violin", "Azmir Halilović - harmonika/accordion"],
    repertoar: [
  
    ],
    link: "",
  },
  {
    id: 7,
    title: "Mladi za mlade - Koncert talenata Muzičkih škola u Varešu i Zenici | RISING STARS - NEXT GENERATION",
    date: "20.10.2022 - 19:00",
    location: "RADNIČKI DOM VAREŠ",
    artists: [
      
    ],
    repertoar: [],
    link: "",
  },
  {
    id: 8,
    title: "Završni koncert i projekcija filma u produkciji BHRT-a „Muzika koju zovem dom“ | Festive Closing Concert and screening of the BHRT documentary „Music I Call Home“ ",
    date: "21.10.2023 - 19:00",
    location: "RADNIČKI DOM VAREŠ",
    artists: [
      "Andrea Nikolić, viola",
      "Stipe Bilić - klavir/piano"
    ],
    repertoar: [],
    link: "",
  },
];

export const passed_events = [
  {
      id: 1,
      title: "VaClaF 10 - BOROVICA 100",
      date: "5.8.2022 - 19:00",
      location: "Župna crkva sv. Mihovila Vareš",
      artists: [
        "Sarajevski gradski orkestar",
        "Andrea Nikolić - violina",
        "Marijana Grabovac - mezzosopran",
        "Fuad Šetić - dirigent"
      ],
      repertoar: ["Vivaldi", "Händel", "Purcell", "Bach"        ],
      link: "https://www.facebook.com/events/515812520307665/?acontext=%7B%22event_action_history%22%3A[%7B%22surface%22%3A%22page%22%7D]%7D"
    },
    {
      id: 2,
      title: "VaClaF 10 - Dances & Songs",
      date: "11.8.2022 - 19:00",
      location: "Stara crkva sv. Mihovila Vareš",
      artists: [
        "Vedrana Šimić - sopran",
        "Elvis Sivčević - gitara",
        "Andrea Nikolić - violina",
        "Sanel Redžić - gitara",
      ],
      repertoar: ["De Falla", "Cossetto", "Bellafronte", "Piazzolla"],
      link: "https://www.facebook.com/events/591837695774621/?acontext=%7B%22event_action_history%22%3A[%7B%22surface%22%3A%22page%22%7D]%7D"
    },
]