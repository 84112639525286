import React, { Component } from "react";
import YouTube from "react-youtube-embed";
import { data } from "../data/eng_data";

export default class Media extends Component {
  render() {
    const yt = data.youtubeLinks;
    return (
      <div className="container fadeIn duration-700 p-70">
        <h1>Media</h1>
        <div className="row justify-content-center">
          {yt.map((ytItem) => (
            <div className="col-sm-6" key={ytItem.id}>
              <YouTube id={ytItem.id} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}
