import React, { Component } from "react";

export default class Press2020 extends Component {
  render() {
    return (
      <div className="container fadeIn duration-700 p-70">
        <h1>"In Exile"</h1>

        <span className="badge badge-secondary mt-1">Press Release</span>

        <h5>27.09. - 04.10.2020</h5>
        <p className="font-weight-bold">
          Osmo izdanje Vareš Classic Festivala "U egzilu", ne odustajući od
          svoje misije ni u najtežim trenucima, gotovo nemogućim uvjetima i
          okolnostima, i ove nepredvidive 2020. godine predstavlja vrhunsku
          umjetnost i umjetnike, iz BiH i inozemstva, angažiranu, životno važnu
          i - nadamo se, koliko god je u našim mogućnostima - dostupnu svima.
          <br />
          Egzil iz koncertnih dvorana u "sigurnost" naših domova, ili egzil iz
          naših domova u potrazi za boljim sutra, tema je toliko aktualna da je
          apsolutno neizbježna. Suočeni smo s iznimnim vremenima, u kojima
          osjećamo da nam je dužnost nastaviti kreirati, boriti se, disati,
          živjeti za umjetnost.
        </p>
        <blockquote className="blockquote">
          <p className="mb-0">Kada, ako ne sad? Tko, ako ne mi?</p>
        </blockquote>
        <p className="font-weight-light">
          Okosnica ovogodišnjeg festivala tri su posebna koncerta: u suradnji s
          Exil.Arte Zentrum Beč, Austrijskim kulturnim forumom Sarajevo i
          Universität für Musik und darstellende Kunst Wien, WISE: Wien
          International Soloists Ensemble i prijatelji - Nataša Veljković,
          Josipa Bainac, Stipe Bilić, Marta Potulska, Ulrike Anton, Edua Zadory,
          Liliana Kehayova, Gerold Gruber, Ivana Nikolić, David Hausknecht i
          Andrea Nikolić - će koncertima uživo iz Beča predstaviti austrijske
          suvremene kompozitorice i kompozitore te kompozitorice i kompozitore
          koji su morali u egzil iz Austrije za vrijeme drugog svjetskog rata,
          uz pjesme neizbježnog L. van Beethovena te dodatak tradicionalnih
          pjesama iz Međimurja, ali i sefardskih pjesama iz Bosne i Hercegovine
          Emila Cossetta te naposljetku završni koncert posvećen isključivo
          ženama. Stoga smo zaista neizmjerno ponosni na ovaj toliko poseban i
          jedinstven program VaClaF-a 2020, duboko zahvalni svim umjetnicima i
          podupirateljima te Vas sve od srca pozivamo da nam se pridružite na
          ovom uzbudljivom putovanju.
          <br />
          Sve koncerte VaClaF-a u egzilu moći ćete pratiti direktno i na
          www.vaclaf.com uz iznos ulaznice po vlastitom izboru, direktno uplativ
          na račun Vareš Classic Festivala, a za koje će linkovi biti dostupni
          uskoro. Budite uz nas! Naposljetku, molimo Vas za razumijevanje i
          podršku u ovim teškim vremenima, pogotovo ukoliko dođe do
          nepredviđenih izmjena u posljednji trenutak. Radujemo se svakoj
          sekundi podijeljene Umjetnosti sa Vama! <br />
          <br />
          Dobrodošli! Srdačno, Andrea Nikolić
        </p>
        <hr />
        <span className="badge badge-primary">English</span>
        <p className="font-weight-bold mt-2">
          The 8th edition of Vareš Classic Festival "In Exile", without giving
          up on its mission in the most difficult times and circumstances, will,
          even in this most uncertain year 2020, present exceptional art and
          artists from Bosnia and Herzegovina (BH) and abroad. Art that is
          always engaged, pertinent and available to all.
          <br />
          Exile - from concert halls into the "safety" of our homes, or from our
          homes in search for a better tomorrow - is a theme so relevant that it
          is currently unavoidable. We face extraordinary times in which we feel
          it is our duty to continue to create, to fight, to breathe, and to
          live for art. When, if not now? Who, if not us? We will therefore hold
          several concerts in Vareš for our beloved audience while ensuring the
          safety of those who attend by adhering to all the epidemiological
          measures. We have also brought together our international associate
          artists to collaborate from abroad. These contributions, and most of
          the festival, will be available online.
        </p>
        <p className="font-weight-light">
          Artists from Bosnia and Herzegowina - SA Sinfonietta and the Wind
          Quintet of Sarajevo Philarmonic - will open the festival in front of
          the audience in Vareš with the "Miholjski" concert on Sunday 27
          September. They will mark Ludwig van Beethoven's 250th birthday by
          performing his work alongside BH composer Ališer Sijarić and the
          renowned French composer Jacques Ibert. <br />
          <br />
          In collaboration with the Croatian National Theatre in Varaždin and
          Istrian National Theatre in Pula, VaClaF will stage an opera for the
          first time; Caccia Lontana by Antonio Smareglia will be streamed live
          from Istrian National Theatre in Pula.
          <br />
          Tanja Stupar Trifunović will present her excellent works in the
          literary part of the festival, while the young hopes of the BH music
          scene will be represented by the violinist Tamara Arsovski and the
          pianist Zerina Šabotić in a live concert in Vareš.
          <br />
          <br />
          At the core of this year's festival are three very special concerts.
          WISE: Wien International Soloists Ensemble & friends - Nataša
          Veljković, Josipa Bainac, Stipe Bilić, Marta Potulska, Ulrike Anton,
          Edua Zadory, Liliana Kehayova, Gerold Gruber, Ivana Nikolić, David
          Hausknecht, Andrea Nikolić - will, in collaboration with Exil.Arte
          centre in Vienna, Austrian Cultural Forum Sarajevo, and the University
          for Music and Performing Arts Vienna, present concerts live from
          Vienna featuring works by Austrian contemporary composers and
          composers who fled Austria into exile during the Second World War.
          <br />
          <br />
          The first concert will, inevitably, feature lieder by Beethoven along
          with traditional songs from Međimurje, songs by Exile composers
          Wilhelm Grosz and Hans Gál and a string quartet by the Exile composer
          Julius Bürger. The second concert will feature a contemporary Concerto
          by Meinhard Rüdenauer, Sephardic songs from Bosnia and Herzegovina by
          Emil Cossetto and a Concertino by Hans Gál. The final concert will be
          dedicated to works by contemporary and Exile women composers: Gabriele
          Proy, Lera Auerbach, Thea Musgrave, Vally Weigl, Johanna Doderer.
          <br />
          <br />
          We are incredibly proud of the uniquely special programme of VaClaF
          2020 and immensely and deeply grateful to our artists and supporters.
          We warmly invite you all to join us on this exciting and unforgettable
          journey.
          <br />
          <br />
          You will also be able to listen to all the concerts of VaClaF "In
          Exile" directly on www.vaclaf.com. There, you can choose to support
          the festival by donating for a ticket, which can be paid into Vares
          Classic Festival account. We kindly ask you for your help and
          understanding in these difficult times and circumstances, while we are
          looking forward to every second of the Art shared with you at our
          festival!
          <br />
          <br />
          Welcome! Sincerely yours, Andrea Nikolić
          <br />
          <br />
          Design and visual Exile concept: Hessamedin Fana
        </p>
      </div>
    );
  }
}
