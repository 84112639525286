import React from "react";
import { Link } from "react-router-dom";
// import HeaderLogo from '../images/2022/vaclaf_10.png'
const Home2023 = () => {
  return (
    <header className="ten-full-bg fadeIn duration-700 vh-100">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-12 text-center">
              {/* <img
                src={HeaderLogo}
                className="img-fluid w-50 pinIn duration-2000"
                alt="vaclaf header"
              /> */}
              <div className="d-flex justify-content-center mt-5">
                <Link to="/events">
                  <button type="button" className="ten-cta-button">
                    Explore Vaclaf 11
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
  );
};

export default Home2023;
