// Image Import
import Vaclaf10 from "../images/programme/vaclaf_10.png";
import Vaclaf9 from "../images/programme/vaclaf_9.png";
import Vaclaf8 from "../images/programme/vaclaf_8.png";
import Vaclaf7 from "../images/programme/vaclaf_7.png";
import Vaclaf6 from "../images/programme/vaclaf_6.png";
import Vaclaf5 from "../images/programme/vaclaf_5.png";
import Vaclaf4 from "../images/programme/vaclaf_4.png";
import Vaclaf3 from "../images/programme/vaclaf_3.png";
import Vaclaf2 from "../images/programme/vaclaf_2.png";
import Vaclaf1 from "../images/programme/vaclaf_1.png";

// Pdf Imports
import Vaclaf10pdf from "../assets/pdf/10_Booklet_050822 _VaClaF10_Introduction1.pdf";
import Vaclaf9pdf from "../assets/pdf/9_Booklet VaClaF 2021_Ethos.pdf";
import Vaclaf8pdf from "../assets/pdf/8_Booklet 2020 InExile.pdf";
import Vaclaf7pdf from "../assets/pdf/7_Booklet 2019 Panta Rhei.pdf";
import Vaclaf6pdf from "../assets/pdf/6_2018_Music I call Home _ Booklet.pdf";
import Vaclaf5pdf from "../assets/pdf/5_2017-Zajedno (Poster & Booklet).pdf";
import Vaclaf4pdf from "../assets/pdf/4_2016-Ljubav (muzika) bez granica.pdf";
import Vaclaf3pdf from "../assets/pdf/3_2015-Quartet plus.pdf";
import Vaclaf2pdf from "../assets/pdf/2_2014-Život je pjesma.pdf";
import Vaclaf1pdf from "../assets/pdf/01_2013-Sonate & improvizacije.pdf";

// Slides
import Slide1 from "../images/carousel/slide_1.jpg";
import Slide2 from "../images/carousel/slide_2.jpg";
import Slide3 from "../images/carousel/slide_3.jpg";
import Slide4 from "../images/carousel/slide_4.jpg";
import Slide5 from "../images/carousel/slide_5.jpg";
import Slide6 from "../images/carousel/slide_6.jpg";
import Slide7 from "../images/carousel/slide_7.jpg";
import Slide8 from "../images/carousel/slide_8.jpg";

export const data = {
  artists: [
    {
      id: 0,
      title: "Musicians",
      description:
        "Nataša Veljković, Andrej Bielow, Saša Mirković, Borut Zagoranski, Marta Potulska, Stipe Bilić, Sabine Weyer, Bow vs. Plectrum (Hojsak & Novosel), Matija Mejić, Martina Menegoni, Josipa Bainac, Tamara    Ivaniš, Krešimir Pustički, Marco Graziani, Domagoj Guščić, Adi Šehu,      Željko Sertić, Rajmond Likić, Rafael Dužnović, Jevgēnijs Čepoveckis,      Milica Zulus, Gayane Mirzoyan, Anna Magdalena Kokits, Nora Romanoff      Schwarzberg, Jure Cerkovnik, Ivana Nikolić, Doris Nikolić, Bas Jongen, Katarina Kutnar, Susanne Schäffer, Anna Firsanova, Belma Alić, Azra Medić, Bartolomej Stanković, Ensemble Corona, Vedrana Šimić, Aida Čorbadžić, Zana Staniškovska, Moron Konjević, Alma Dizdar, Tamara Arsovski, Aida Deljkić, Esma Cerkovnik, Radost Galonja, Iztok Kocen, David Mastikosa, Dragana Tabak, Olja Janjuš, Petra Zidarić Györek, Asmir Jakupović, Timur Sijarić, Amela Frljučkić, Strings attached sinfonietta, Banja Luka Philharmonic, Sarajevo Philharmonic, Ulrike Anton, Gerold Gruber, Gabriele Proy, Meinhard Rüdenauer, Johanna Doderer, Liliana Kehayova, Jonathan Cano, Amir Amiri, Oberton String Octet, Nataša Mirković, Michel Godard, MAS String Orchestra, MAS i SA Sinfonietta Trio i Kvartet, Fahrudin Strojil, Damir Imamović, Sarajevski gradski orkestar, Fuad Šetić, Marijana Nikolić, Elvis Sivčević, Sanel Redžić, WISE Wind Quintet, Pablo Harpio Hernández, Ayaka Matsudomi, Yolotzin Cruz Cedillo, Jason Pfiester, Wu Bing, Belma Šarančić, Camerata Balkania, Jovanka Višekruna Janković, Nemanja Adamović, Nejla Komar, Tibor Naglić, Mladen Tarbuk",
    },
    {
      id: 1,
      title: "Artists in residence",
      description: "WISE: Wien International Soloists Ensemble",
    },
    {
      id: 2,
      title: "Painters and visual artists",
      description:
        "Stjepan Šimić - Pepi, Hrvoje Marko Peruzović, Tamara Herceg, Vesna Davidovič, Hilda Dugonjić, Jerca Šantej and many more…",
    },
    {
      id: 3,
      title: "Writers",
      description:
        "Adisa Bašić, Faruk Šehić, Senka Marić, Marina Veličković, Lamija Begagić, Nedžma Čizmo, Tanja Stupar Trifunović  ",
    },
    {
      id: 4,
      title: "Designers and architects",
      description:
        "Ana Slugić, Oliver Potočnik, Majda Redžepagić, Hessamedin Fana (VaClaF's head of visual content since 2017)",
    },
    {
      id: 5,
      title: "",
      description:
        "Vareš Parish Choir, Vareš musicians, professors and young pianists of Vareš Music School, tamburaši and rock bands led by prof. Igor Hinger, as well as numerous young, up and coming talents, and many more!",
    },
  ],
  programme: [
    {
      id: 0,
      title: "Vaclaf 1 - Sonate i Improvizacije",
      description:
        "A premiere: our first VacLaf has three main thematic concerts, plus open rehearsals and workshops in the beutiful Vareš venues for the most wonderful audience.",
      image: Vaclaf1,
      pdf: Vaclaf1pdf,
    },
    {
      id: 1,
      title: "Vaclaf 2 - Život je pjesma",
      description:
        "Second edition is bringing many innovations and first pieces written for VaClaF. For the first time ever contemporary classical music is being played in the old blacksmith workshops from the 17th century in Oćevija.",
      image: Vaclaf2,
      pdf: Vaclaf2pdf,
    },
    {
      id: 2,
      title: "Vaclaf 3 - Quarter Plus",
      description:
        "With the theme Quartet + VaClaF 3 is exploring the unusual extensions of the classical quartet and bringing together the polar opposites. Music for the Beginning and the End of Time.",
      image: Vaclaf3,
      pdf: Vaclaf3pdf,
    },
    {
      id: 3,
      title: "Vaclaf 4 - Ljubav (muzika) bez granica!",
      description:
        "'Love (Music) Without Borders' continues the story of the previous festival and brings VaClaF's concept to yet another level. 4th VaClaF is a part of the Year of Culture Bosnia and Herzegovina - Austria 2016.",
      image: Vaclaf4,
      pdf: Vaclaf4pdf,
    },
    {
      id: 4,
      title: "Vaclaf 5 - Zajedno - Zusammen - Together",
      description:
        "Fifth anniversary of the Festival is connecting art and artists from BiH and all over the world into an unique interdisciplinary festival concept. Art: ZaJedno / T(w)ogether / Zusamme(l)n.",
      image: Vaclaf5,
      pdf: Vaclaf5pdf,
    },
    {
      id: 5,
      title: "Vaclaf 6 - Music I call Home",
      description:
        "'Music I call Home', sixth festival edition, celebrates 10 years of the first 'Miholjski' concert and music as our universal and eternal Home. Music that connects and brings us where we belong.",
      image: Vaclaf6,
      pdf: Vaclaf6pdf,
    },
    {
      id: 7,
      title: "Vaclaf 7 - Panta Rhei",
      description:
        "'Panta Rhei' continues the festival's story into its seventh edition with a special emphasis on the BiH female artists and the magic of the number 7. Everything flows.",
      image: Vaclaf7,
      pdf: Vaclaf7pdf,
    },
    {
      id: 8,
      title: "Vaclaf 8 - In Exile",
      description:
        "'The 8th edition of Vareš Classic Festival 'In Exile', without giving up on its mission in the most difficult times and circumstances, will, even in this most uncertain year 2020, present exceptional art and artists from Bosnia and Herzegovina (BH) and abroad. Art that is always engaged, pertinent and available to all.'",
      image: Vaclaf8,
      pdf: Vaclaf8pdf,
    },
    {
      id: 9,
      title: "Vaclaf 9 - Ethos",
      description:
        "'Ethos” - the characteristic spirit of a culture, era, or community as manifested in its attitudes and aspirations is the theme for the 9th edition of our Vareš Classic Festival. Music in all its forms, best of quality, connecting the World in Vareš.'",
      image: Vaclaf9,
      pdf: Vaclaf9pdf,
    },
    {
      id: 10,
      title: "Vaclaf 10",
      description:
        "'VaClaF 10 is celebrating its first decade through the question: “Back, to the Future?” , as well as some wonderful collaborations as an Introduction and Special programme. Passion, Art, Music for all. From Vareš with love.'",
      image: Vaclaf10,
      pdf: Vaclaf10pdf,
    },
  ],
  slides: [
    {
      id: 0,
      title: "VacLaF",
      subtitle: "Nulla vitae elit libero, a pharetra augue mollis interdum",
      url: Slide1,
    },
    {
      id: 1,
      title: "VacLaF 2018",
      subtitle: "Otvorenje A. Nikolić i N. Veljković",
      url: Slide2,
    },
    {
      id: 2,
      title: "VacLaF 2018",
      subtitle: "10 godina kod kuće Pjesme moje majke",
      url: Slide3,
    },
    {
      id: 3,
      title: "VacLaF 2018",
      subtitle: "Francuska veza",
      url: Slide4,
    },
    {
      id: 4,
      title: "VacLaF 2018",
      subtitle: "Next generation",
      url: Slide5,
    },
    {
      id: 5,
      title: "VacLaF",
      subtitle: "Nulla vitae elit libero, a pharetra augue mollis interdum",
      url: Slide6,
    },
    {
      id: 6,
      title: "VacLaF",
      subtitle: "Nulla vitae elit libero, a pharetra augue mollis interdum",
      url: Slide7,
    },
    {
      id: 7,
      title: "VacLaF 2018",
      subtitle: "J.B. Pergolesi Stabat mater",
      url: Slide8,
    },
  ],
  menuLinks: [
    {
      id: 1,
      name: "About",
      link: "/about",
    },
    {
      id: 2,
      name: "Programme",
      link: "/programme",
    },
    {
      id: 3,
      name: "Media",
      link: "/media",
    },
    {
      id: 4,
      name: "Press",
      link: "/press",
    },
    {
      id: 5,
      name: "Support",
      link: "/donate",
    },
    {
      id: 6,
      name: "Events",
      link: "/events",
    },
    {
      id: 7,
      name: "Contact",
      link: "/contact",
    },
  ],
  youtubeLinks: [
    {
      id: "XHUbYz8NoEE",
      name: "Dimenzija više 27.09.2019",
    },
    {
      id: "BalEGHWxaWw",
      name: "David Mastikosa: Ul-la for violin and string orchestra | Andrea Nikolić & MAS String Orchestra",
    },
    {
      id: "c0rdHw5OGxs",
      name: "Prilog 21 Vaclaf",
    },
    {
      id: "FvauPHG-wFo",
      name: 'VaClaF 2016: Vareš Classic Festival - Opening Concert "The Tragedy of Love (Love and Death")',
    },
  ],
  donate_info: [
    {
      id: 0,
      text: 'Udruga prijatelja glazbe "Emil Milo Cipra"',
    },
    {
      id: 1,
      text: "Ulica Zvijezda K4, 71330 Vareš, BiH",
    },
    {
      id: 2,
      text: "ID: 4218850610002",
    },
    {
      id: 3,
      text: "Giro account BiH (KM): 3385802224256342 IBAN: BA39 3385 8048 2425",
    },
    {
      id: 4,
      text: "7728 SWIFT: UNCRBA 22 Purpose: Vareš Classic Festival",
    },
  ],
  press_releases: [
    {
      id: 0,
      year: 2021,
      name: "Ethos",
      lang: [{ id: 0, name: "BHS" }],
      component: "../components/press/Press2021.js",
    },
    {
      id: 1,
      year: 2020,
      name: "In Exile",
      lang: [
        { id: 0, name: "BHS" },
        { id: 1, name: "English" },
      ],
      component: "../components/press/Press2020.js",
    },
  ],
};
