import React from "react";
import { IoLogoFacebook, IoLogoInstagram } from "react-icons/io";

const Footer = () => {
  return (
    <footer class="footer">
      <div class="container d-flex justify-content-around">
        <span class="text-muted">
          &copy; Copyright, 2013 - {new Date().getFullYear()}, Vaclaf
        </span>
        <span className="social">
          <a
            className="social-link"
            href="https://www.facebook.com/VaresClassicFestival"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoLogoFacebook size={"1.5em"} />
          </a>
          <a
            className="social-link"
            href="https://www.instagram.com/varesclassicfestival"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoLogoInstagram size={"1.5em"} />
          </a>
        </span>
      </div>
    </footer>
  );
};

export default Footer;
