import React, { Component } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { data } from "../data/eng_data";

export default class Programme extends Component {
  render() {
    const programme = data.programme;
    return (
      <div className="container p-70 fadeIn duration-700">
        <h1>Programme</h1>
        <p className="lead">
          VaClaF: Vareš Classic Festival saw its premiere in September 2013, on
          the fifth anniversary of the traditional "Miholjski" concert of
          classical music in Vareš. Its goal is to bring high quality music and
          art to the widest possible audience through a collaboration of top
          class artists from Bosnia and Herzegovina and all around the world.{" "}
        </p>

        {programme.reverse().map((item) => (
          <div className="row pt-5" key={item.id}>
            <div className="col-md-7 programme-hover">
              <a href={item.pdf} target="_blank" rel="noopener noreferrer">
                <LazyLoadImage
                  className="img-fluid rounded mb-3 mb-md-0"
                  src={item.image}
                  alt={item.title}
                />
              </a>
            </div>
            <div className="col-md-5">
              <h3>{item.title}</h3>
              <p>{item.description}</p>
              <a
                className="btn-p"
                href={item.pdf}
                target="_blank"
                rel="noopener noreferrer"
              >
                More...
              </a>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
